import request from '@/utils/request'
export function getZNProductList (params) {
return request({
    url: '/api/Product/List',
    data: {...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}

// 获取文章详情
export function getProductInfo (id) {
  return request({
    url: `/api/Product?para=${id}`
  })
}
// /api/Product/GetName
export function getProductName(id){
  return request({
    url: `/api/Product/GetName?id=${id}`
  })
}

export function getZNProductClassList (params) {
  return request({
      url: '/api/Product/ClassList',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }
  


