import request from '@/utils/request'
export function getArticleList (params) {
  return request({
    url: '/api/Article/List',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

// 获取文章详情
export function getArticleInfo (id) {
  return request({
    url: `/api/Article?para=${id}`
  })
}

// 获取文章类型
export function getArticleTypeItem (id) {
  return request({
    url: `/api/Article/GetType?id=${id}`
  })
}
// 获取文章同类信息
export function getArticleSameTypeShow (id) {
  return request({
    url: `/api/Article/GetArticleSameTypeShow?id=${id}`
  })
}

export function getArticleTypeList (params) {
  return request({
    url: '/api/Article/TypeList',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}


export function getArticleListPlus (params) {
  return request({
    url: '/api/ArticlePlus/List',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

// 获取文章详情
export function getArticleInfoPlus (id) {
  return request({
    url: `/api/ArticlePlus?para=${id}`
  })
}

export function getArticleTypeListPlus (params) {
  return request({
    url: '/api/ArticlePlus/TypeList',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}
