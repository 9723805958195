<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-search
      v-if="subTypeOption.length == 0"
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-row v-if="subTypeOption.length > 0">
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.subTypeIds"
            :options="subTypeOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.title"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell
      v-if="page.filterData.productId != '00000000-0000-0000-0000-000000000000'"
    >
      <van-col span="24">
        <van-tag
          v-if="
            page.filterData.productId != '00000000-0000-0000-0000-000000000000'
          "
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closeproduct"
        >
          {{ productName }}
        </van-tag>
      </van-col>
    </van-cell>

    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
  长按图片可下载或分享， 本模块可免登录查看
</van-notice-bar>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >

  <div class="van-clearfix">
        <div
          class="layout_wrapper"
          style="
            float: left;
            width: 50%;
            text-align: center;
            background-color: #f8f8f8;
            overflow: hidden;
          "
          @click="toArticleDetail(item.id.toString())"
          v-for="item in articles"
          :key="item.id.toString()"
        >
          <div class="product-layout__item">
            <van-image
              lazy-load
              class="w100"
              fit="contain"
              style="max-height: 200px"
              :src="item.imageUrl"
            />

            <div style="height: 42px">
              <h3 style="display: inline-block; height: 21px">
                {{ item.title }}
              </h3>
            </div>
          </div>
        </div>
      </div>


  
    </van-list>
      </van-pull-refresh>
  </div>
</template>

<script>
import { getArticleListPlus, getArticleTypeListPlus } from "@/api/article";
import { getArticleType } from "@/utils/datetype";
import { getProductName } from "@/api/product";
//const key = "zn-history";
export default {
  name: "znarticle",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      productName: "",
      subTypeOption: [],
      type: 100,
      title: "",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          subTypeIds: "",
          title: "",
          typeId: 100,
          productId: "00000000-0000-0000-0000-000000000000",
        },
      },
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      let type = 108;//this.$route.query.type;
      this.type = type;
      this.title = getArticleType(type);
      document.title=this.title;
      this.page.filterData.typeId = type;

      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.page.filterData.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      let subTypeList = await getArticleTypeListPlus({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: this.type,
        },
      });
      if (subTypeList.data.data.length > 0) {
        this.subTypeOption = [{ text: "全部", value: "" }];
        subTypeList.data.data.map((x) =>
          this.subTypeOption.push({ text: x.typeName, value: x.id.toString() })
        );
      }
    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toArticleDetail(id) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/z",
        query: { p: id, t: this.page.filterData.typeId },
      });
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getArticleListPlus({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 150px;
  }
}
.zntitle {
  height: 150px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
