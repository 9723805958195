export function getArticleType(type) {
  if (type == 100) {
    return "中酿资讯";
  }
  if (type == 101) {
    return "中酿公告";
  }
  if (type == 102) {
    return "进阶S7技能加持";
  }
  if (type == 103) {
    return "荣誉奋斗者";
  }
  if (type == 106) {
    return "市场宣传";
  }
  if (type == 107) {
    return "系统说明";
  }
  if (type == 108) {
    return "中酿内刊";
  }
  if (type == 110) {
    return "中酿企宣";
  }
  if (type == 111) {
    return "制度须知";
  }
  if (type == 112) {
    return "审计公示";
  }
  if (type == 300) {
    return "百年糊涂";
  }
  return "";
}

//2023-7 跟进excel表格数据整理
export const BrandDepartmentTree = [
  {
    "text": "品牌一部",
    "id": "品牌一部",
    children: [
      { text: "北京区域", id: "北京区域" },
      { text: "上海区域", id: "上海区域" },
      { text: "广东区域", id: "广东区域" },
      { text: "山东市场", id: "山东市场" },
      { text: "四川区域", id: "四川区域" },
      { text: "天津市场", id: "天津市场" }
    ]
  },
  {
    "text": "品牌二部",
    "id": "品牌二部",
    children: [
      { text: "江苏市场", id: "江苏市场" },
      { text: "河南市场", id: "河南市场" },
      { text: "浙江市场", id: "浙江市场" },
      { text: "福建市场", id: "福建市场" },
      { text: "安徽市场", id: "安徽市场" },
      { text: "湖北市场", id: "湖北市场" },
      { text: "湖南市场", id: "湖南市场" },
      { text: "重庆市场", id: "重庆市场" },
      { text: "河北市场", id: "河北市场" },
      { text: "陕西市场", id: "陕西市场" },

      { text: "辽宁市场", id: "辽宁市场" },
      { text: "新疆市场", id: "新疆市场" },

      { text: "甘肃市场", id: "甘肃市场" },
      { text: "广西市场", id: "广西市场" },


      { text: "黑龙江市场", id: "黑龙江市场" },

      { text: "吉林市场", id: "吉林市场" },

      { text: "江西市场", id: "江西市场" },



    ]
  }

];


export const BrandDepartmentTree2 = [
  {
    "text": "品牌一部",
    "id": "品牌一部",
    children: [
      { text: "品牌一部全部", id: "品牌一部全部" },
      { text: "北京区域", id: "北京区域" },
      { text: "上海区域", id: "上海区域" },
      { text: "广东区域", id: "广东区域" },
      { text: "山东市场", id: "山东市场" },
      { text: "四川区域", id: "四川区域" },
      { text: "天津市场", id: "天津市场" }
    ]
  },
  {
    "text": "品牌二部",
    "id": "品牌二部",
    children: [
      { text: "品牌二部全部", id: "品牌二部全部" },
      { text: "江苏市场", id: "江苏市场" },
      { text: "河南市场", id: "河南市场" },
      { text: "浙江市场", id: "浙江市场" },
      { text: "福建市场", id: "福建市场" },
      { text: "安徽市场", id: "安徽市场" },
      { text: "湖北市场", id: "湖北市场" },
      { text: "湖南市场", id: "湖南市场" },
      { text: "重庆市场", id: "重庆市场" },
      { text: "河北市场", id: "河北市场" },
      { text: "陕西市场", id: "陕西市场" },

      { text: "辽宁市场", id: "辽宁市场" },
      { text: "新疆市场", id: "新疆市场" },

      { text: "甘肃市场", id: "甘肃市场" },
      { text: "广西市场", id: "广西市场" },


      { text: "黑龙江市场", id: "黑龙江市场" },

      { text: "吉林市场", id: "吉林市场" },

      { text: "江西市场", id: "江西市场" },



    ]
  }

];